/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Header from "../Layout/Header";
import { apiUrl } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst, showPriceValue } from "../Helpers/SettingHelper";

class RedeemQRCode extends Component {
  constructor(props) {
    super(props);
    var usercredits =
      localStorage.getItem("usercredits") === null
        ? 0
        : localStorage.getItem("usercredits");
    this.state = {
      current_page: "Redeem Voucher",
      qr_details: [],
      qr_detail_error: "",
      user_credits: usercredits,
      redeemqr_error: "",
    };

    if (parseFloat(usercredits) > 0) {
      localStorage.removeItem("usercredits");
    }

  }
  componentDidMount() {
    //$("body").addClass("hide-overlay");

    var qrcodetxt =
      localStorage.getItem("qrcodetxt") === null
        ? ""
        : localStorage.getItem("qrcodetxt");
    if (qrcodetxt !== "") {
      //localStorage.removeItem("qrcodetxt");
      this.getVoucherData(qrcodetxt);
    } else {
      this.props.history.push("/");
    }
  }

  getVoucherData(qrCodeVal) {
    var postObject = {
      QRCode: qrCodeVal
    };
    showLoaderLst("redeem-page-main", "class");
    axios
      .post(apiUrl + "merchant/readQrcode", postObject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        hideLoaderLst("redeem-page-main", "class");
        if (res.data.status === "ok") {
          this.setState({ qr_details: res.data.result, user_credits:res.data.result.redeemPoints });
        } else {
          this.setState({ qr_details: Array(), qr_detail_error: "yes" });
        }
      });
  }

  goBackTo(event) {
    event.preventDefault();
    this.props.history.push("/menu");
  }

  comfirmRedeemFun(event) {
    event.preventDefault();
    let qrDetails = this.state.qr_details;
    let userCredits = this.state.user_credits;
    console.log(qrDetails, 'qrDetailsqrDetails')
    if (qrDetails.length > 0 && ((qrDetails[0].QRType == "products" && qrDetails[0].voucherID!=="") || (qrDetails[0].QRType == "redeem" && qrDetails[0].voucherID!=="") || (qrDetails[0].QRType == "points" && parseFloat(userCredits) > 0) )
    ) {
      var postObject = {
        QRCode: qrDetails[0].QRCode,
        QRID: qrDetails[0]._id,
        userCredits: userCredits,
      };

      showLoaderLst("redeem-btn-cls", "class");
      axios
        .post(apiUrl + "merchant/redeemQrcode", postObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          hideLoaderLst("redeem-btn-cls", "class");
          if (res.data.status === "ok") {
            Swal.fire({
              icon: "success",
              title: "Redeemed!",
              text: "Voucher Redeemed Successfully",
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            }).then((dataarr) => {
              this.props.history.push("/transactions");
            });
            //this.setState({ qr_details: res.data.result_set });
          } else {
            this.setState({ redeemqr_error: res.data.message });
          }
        }).catch((e) => {
          hideLoaderLst("redeem-btn-cls", "class");
          var errorMsg = e?.response?.data?.massage || e.message;
          this.setState({ redeemqr_error: errorMsg });
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ redeemqr_error: "Invalide QR" });
    }
  }

  render() {
    let qrDetails = this.state.qr_details;
    return (
      <div className="main-div redeem-page-main">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />


        <div className="rel redeem-page-inner">
          <div className="container">
            {Object.keys(qrDetails).length > 0 ? (
              <div className="redeem-profie">
                <div className="redeem-profie-box">
                  <figure></figure>
                  <figcaption>
                    <h2>{qrDetails[0].customerName}</h2>
                    <span>{qrDetails[0].customerPhone}</span>
                     <br/>
                      <label><b>Transaction ID:</b> {qrDetails[0]?.QRtransactionID||''}</label><br/>
                      <label><b>Transaction Time:</b> {(qrDetails.length>0)?format(new Date(qrDetails[0].createdAt), "yyyy-MM-dd hh:mm b"):''}</label>
                      <label><b>Valid Till :</b> {qrDetails[0].voucherExpiryDate!==''? format(new Date(qrDetails[0].voucherExpiryDate), "dd/MM/yyyy") : "-"}</label>
                  </figcaption>
                </div>

                {qrDetails[0].QRType == "products" || qrDetails[0].QRType === "redeem" ? (
                  <div className="details payment-details">
                    <div className="payment-info">
                      <div className="paid-info">
                        <div>
                          <strong>{qrDetails[0].productName}</strong>
                        </div>
                        <div>
                          {" "}
                          {qrDetails[0].QRType !== "redeem" && (
                          <span>
                            {qrDetails[0].redeemPoints} Points
                          </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  this.state.user_credits!=="" &&(
                    <div className="details payment-details">
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Redeem Cash Vouchers</strong>
                          </div>
                          <div>
                            <span>
                            {showPriceValue(this.state.user_credits)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                {this.state.redeemqr_error != "" && (
                  <p className="error_info">{this.state.redeemqr_error}</p>
                )}
                <div className="redeem-profie-btn textcenter">
                  <a
                    href={void 0}
                    className="button ghost-btn"
                    onClick={this.goBackTo.bind(this)}
                  >
                    Cancel
                  </a>
                  <a
                    href={void 0}
                    className="button redeem-btn-cls"
                    onClick={this.comfirmRedeemFun.bind(this)}
                  >
                    Confirm
                  </a>
                </div>
              </div>
            ) : (
              <div className="redeem-profie invalide-qr-div">
                {this.state.qr_detail_error == "yes" && (
                  <>
                    <div className="redeem-profie-box">
                      <div className="invalide-qr-cls">
                        <h2>Invalide QR Code</h2>
                        <span>
                          Sorry This voucher can not be reddemm at this outlet
                        </span>
                      </div>
                    </div>
                    <div className="redeem-profie-btn textcenter">
                      <a
                        href={void 0}
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this)}
                      >
                        Try Agin
                      </a>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RedeemQRCode;
