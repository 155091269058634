/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import "react-spring-bottom-sheet/dist/style.css";
import cookie from "react-cookies";
import { apiUrl, unquieID, baseUrl } from "../Settings/Config";
import Header from "../Layout/Header";
import { IonFooter } from '@ionic/react';
import "@ionic/react/css/core.css";

class CompanyQRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: 'CompanyQRCode',
      genQRCode: ''
    };

    if (cookie.load("LoginUserId") === undefined) {
      props.history.push("/");
    }

    if(unquieID !== undefined && unquieID !== '') {
      this.generateCmpQrcode();
    } else {
      this.props.history.push("/");
    }

  }
  componentDidMount() {
     $("body").addClass("hide-overlay");
  }
 
  generateCmpQrcode() {
    var postObject = {};
        postObject = {
          'app_id': unquieID,
          'site_url': baseUrl
        };
    axios.get(apiUrl + "merchant/generateQR", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(res => {
      if (res.data.status === "ok") {
        this.setState({genQRCode:res.data.genQRCode });
      }
    });
  }

  goBackFun(event) {
    event.preventDefault();
    this.props.history.push("/menu");
  }

  render() {
    if(this.state.genQRCode != '') {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="mbtm-need-less rel">
          <div className="container">
            <div className="voucher-redeem-detail textcenter">
              <div className="vod-header">
                <h2>Scan QR Now</h2>
                <p>Please show this QR code to our customer</p>
              </div>
              <div className="vod-body">
                <img src={this.state.genQRCode} />
              </div>
              <br></br>
            </div>
          </div>
        </div>

        <IonFooter collapse="fade">
          <div className="container">
          <div className="sticky-single-btn">
             <a href="#" className="button btn-dark" onClick={this.goBackFun.bind(this)}>
              Cancel
            </a>
          </div>
          </div>
        </IonFooter>
      </div>
    );
   }
  }
}

export default (CompanyQRCode);
