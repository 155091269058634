import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Layout/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import $ from "jquery";
import cookie from "react-cookies";
import { apiUrl, unquieID } from "../Settings/Config";
import {
  showLoaderLst,
  hideLoaderLst,
  showPriceValue,
  showDateFormate,
  showTimeFormate,
  showPriceSeparate,
} from "../Helpers/SettingHelper";
import filteri from "../../common/images/filter.svg";
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Transactions",
      transactionList: [],
      totalTransaction: 0,
      totalTransactionValue: 0,
      totalCashTransaction: 0,
      totalTransactionCashValue: 0,
      totalSpendValue: 0,
      trans_type: "",
      trans_orderby: "",
      search_text: "",
      start_date: "",
      end_date: "",
    };

    if (cookie.load("LoginUserId") === undefined) {
      props.history.push("/");
    }
    this.getTransData();
  }

  getTransData() {
    var postObject = {
      app_id: unquieID,
      user_id: cookie.load("LoginUserId"),
      outlet_id: cookie.load("LoginUserOutlet"),
      trans_type: this.state.trans_type,
      trans_orderby: this.state.trans_orderby,
      search_text: this.state.search_text,
    };

    if (this.state.start_date !== "" && this.state.end_date !== "") {
      postObject.from_date = moment(this.state.start_date).format(
        "Y-MM-DD HH:mm:ss"
      );
      postObject.to_date = moment(this.state.end_date).format(
        "Y-MM-DD HH:mm:ss"
      );
    } else if (this.state.start_date !== "") {
      postObject.from_date = moment(this.state.start_date).format(
        "Y-MM-DD HH:mm:ss"
      );
    }

    showLoaderLst("trans-page-inner", "class");
    axios
      .post(apiUrl + "merchant/getTransactionList", postObject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        hideLoaderLst("trans-page-inner", "class");
        if (res.data.status === "ok") {
          this.setState({
            transactionList: res.data.result,
            totalTransaction: res.data.totalTransaction,
            totalTransactionValue: res.data.totalTransactionValue,
            totalTransactionCashValue: res.data.totalTransactionCashValue,
            totalSpendValue: res.data.totalSpendValue,
          });
        } else {
          this.setState({ transactionList: [] });
        }
      });
  }

  transactionListFun() {
    let transactionList = this.state.transactionList;
    if (Object.keys(transactionList).length > 0) {
      const transactionRowHtml = transactionList.map(
        (transactionRow, rwInt) => {
          var transQRType = transactionRow.transQRType;
          var transProductID = transactionRow.transProductID;
          var transProductPrice = transactionRow.transProductPrice!==null && transactionRow.transProductPrice!==""? parseFloat(transactionRow.transProductPrice): 0;
          var transQRUseredAmount = parseFloat(transactionRow.transQRUseredAmount);
          var transBalanceBillAmount = parseFloat(transactionRow.transBalanceBillAmount);
          var transVoucherBillAmount = parseFloat(transactionRow.transVoucherBillAmount);
          var transQREarnedPoints = parseFloat(transactionRow.transQREarnedPoints);
          var currentBalance = parseFloat(transactionRow.currentBalance);

          var transProductName = transactionRow.transProductName ?? '';
          var transVoucherName = transactionRow.transVoucherName ?? '';

          var displyVoucherName = (transProductName!=="")?transProductName:transVoucherName;
          
          
          var allowsecondTrans = false;
          if((transQRType !== "Redeem Point" && transQRType !== "Redeem Voucher") && (transBalanceBillAmount>0 || (transProductID!==null && transQRUseredAmount>0)))  {
            allowsecondTrans = true
          }

          var firstNote = '';
          var secondNote = '';
          var thirdNote = '';

          var dispyType = '';
          var dispyTypeSecond = '';

          var dispyVocher = '';
          var dispyVocherSecond = '';
          
          var dispyAmt = '';
          var dispyAmtSecond = '';
          if(transQRType === "BillPay" && transQRUseredAmount>0 && transProductPrice===0 && transVoucherBillAmount===0) {
            firstNote = (<>Total Transaction: ${transQRUseredAmount}<br/>Paid using Cash/Card: ${transQRUseredAmount}<br/>
              {transQREarnedPoints>0 &&(
                <>
                Points {transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'}: {showPriceSeparate(transQREarnedPoints, '', 'no')}
                </>
              )
              }</>);
            dispyAmt = (<strong>${transQRUseredAmount} Payment</strong>);
            dispyType = 'Pay Bill';
            dispyVocher = 'Payment';
          }else if(transQRType === "BillPay" && transQRUseredAmount>0 && transProductPrice>0 && transVoucherBillAmount===0) {
            firstNote = (<>Total Transaction: ${transProductPrice}<br/>Paid using Cash/Card: ${transProductPrice}<br/>
              {transQREarnedPoints>0 &&(
                <>
                Points {transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'}: {showPriceSeparate(transQREarnedPoints, '', 'no')}
                </>
              )
              }</>);

            secondNote = (<>Paid using Voucher: ${transQRUseredAmount}<br/>Balance Next Visit: ${currentBalance}</>);
            thirdNote = (<>{transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'} Voucher</>);           
            
            dispyAmt = (<strong>${transProductPrice} voucher</strong>);
            dispyAmtSecond = (<strong>${transQRUseredAmount} Payment</strong>);

            dispyType = 'Buy Voucher';

            dispyTypeSecond = 'Pay Bill';

            dispyVocher = displyVoucherName;
            dispyVocherSecond = 'Payment';

          }else if(transQRType === "BillPay" && transQRUseredAmount>0 && transProductPrice===0 && transVoucherBillAmount>0) {
            if(transBalanceBillAmount>0) {
              firstNote = (<>Total Transaction: ${transQRUseredAmount}<br/>Paid using Cash/Card: ${transBalanceBillAmount}<br/>
                {transQREarnedPoints>0 &&(
                  <>
                  Points {transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'}: {showPriceSeparate(transQREarnedPoints, '', 'no')}
                  </>
                )
                }</>);
              if(transBalanceBillAmount>0) {
                secondNote = (<>Paid using Voucher: ${transVoucherBillAmount}<br/>Balance Next Visit: ${currentBalance}</>);
              }            
              thirdNote = (<>{transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'} Voucher</>);
              
              dispyAmt = (<strong>${transQRUseredAmount} Payment</strong>);
              dispyAmtSecond = (<strong>${transVoucherBillAmount} Cash Voucher</strong>);

              dispyType = 'Pay Bill';

              dispyTypeSecond = 'Voucher Pay';

              dispyVocher = 'Payment';
              dispyVocherSecond = (displyVoucherName!=='Pay')?displyVoucherName:'Pay Via Voucher Balance';
            }else {
              firstNote = (<>Total Transaction: ${transQRUseredAmount}<br/>Paid using Voucher: ${transVoucherBillAmount}<br/>Balance Next Visit: ${currentBalance}<br/>
                {transQREarnedPoints>0 &&(
                  <>
                  Points {transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'}: {showPriceSeparate(transQREarnedPoints, '', 'no')}
                  </>
                )
                }</>);        
            
              dispyAmt = (<strong>${transQRUseredAmount} Payment</strong>);
              dispyType = 'Pay Bill';
              dispyVocher = 'Payment';
            }

            
          }else if(transQRType === "buyvoucher" && transQRUseredAmount===0 && transProductPrice>0 && transVoucherBillAmount===0) {
            firstNote = (<>Buy Voucher: ${transProductPrice}<br/>Paid using Cash/Card: ${transProductPrice}<br/>Balance Next Visit: ${currentBalance}
              </>);
            dispyAmt = (<strong>${transProductPrice} Voucher</strong>);
            dispyType = 'Buy Voucher';
            dispyVocher = displyVoucherName;
            thirdNote = (<>{transactionRow?.transCancel === "Yes"?'Cancelled':'Issued'} Voucher</>);
          }else if(transQRType === "Redeem Point"){
            firstNote = (<>Points Redeemed: {transQRUseredAmount} </>);
            dispyAmt = (<strong>{transQRUseredAmount} Points</strong>);
            dispyType = 'Redeem Points';
            dispyVocher = displyVoucherName;
          }else if(transQRType === "Redeem Voucher"){
            firstNote = (<>Redeem Voucher</>);
            dispyAmt = (<strong>-</strong>);
            dispyType = 'Redeem Voucher';
            dispyVocher = displyVoucherName;
          }
          var allowThirdTrans = false;
          if(transactionRow.transIsFreeProduct!==null) {
            allowThirdTrans = true;
          }
          

          return (
            <React.Fragment key={rwInt}>
              <li>
                <ul>
                  <li className="textcenter">
                    {transactionRow.transactionID}
                    {allowsecondTrans === true || allowThirdTrans === true ? "A" : ""}
                    <br />
                    <br />
                    <span className="trans-date">
                      {showDateFormate(transactionRow.createdAt)}{" "}
                      {showTimeFormate(transactionRow.createdAt)}
                    </span>
                  </li>

                  <li>{dispyType}</li>
                  <li>{dispyVocher}</li>
                  <li>
                    <span>{transactionRow.customer?.customerName || ""}</span>
                    <em>{transactionRow.customer?.customerPhone}</em>
                  </li>
                  <li className="textcenter">
                    {dispyAmt}
                    {transactionRow?.transCancel === "Yes" ? (
                      <a href="#" className="canceled-lnk">
                        Cancelled
                      </a>
                    ) : transactionRow?.allowtocancel === "yes" ? (
                      <a
                        href="#"
                        onClick={this.actCancelFun.bind(this, transactionRow)}
                      >
                        Cancel
                      </a>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>{firstNote}</li>
                </ul>
                {transactionRow.transCancel == "Yes" &&
                  (transactionRow.transactionParentID !== "" ? (
                    <div className="canceled-trans">
                      Cancelled Transaction ID:{" "}
                      {transactionRow?.cancelTransID || ""}
                    </div>
                  ) : (
                    ""
                  ))}
              </li>
              {allowsecondTrans === true && (
                <li>
                  <ul>
                    <li className="textcenter">
                      {transactionRow.transactionID}B<br />
                      <br />
                      <span className="trans-date">
                        {showDateFormate(transactionRow.createdAt)}{" "}
                        {showTimeFormate(transactionRow.createdAt)}
                      </span>
                    </li>

                    <li>
                      {dispyTypeSecond}
                    </li>
                    <li>{dispyVocherSecond}</li>
                    <li>
                      <span>{transactionRow.customer?.customerName || ""}</span>
                      <em>{transactionRow.customer?.customerPhone}</em>
                    </li>
                    <li className="textcenter">{dispyAmtSecond}
                    {transactionRow?.transCancel === "Yes" && (
                      <a href="#" className="canceled-lnk">
                        Cancelled
                      </a>
                    )}
                    </li>
                    <li>{secondNote}</li>
                  </ul>
                  {transactionRow.transCancel == "Yes" &&
                    transactionRow.transactionParentID !== "" && (
                      <div className="canceled-trans">
                        Cancelled Transaction ID:{" "}
                        {transactionRow?.cancelTransID || ""}
                      </div>
                    )}
                </li>
              )}

              {allowThirdTrans === true && (
                <li>
                  <ul>
                    <li className="textcenter">
                      {transactionRow.transactionID}{allowsecondTrans === true ? 'C' : 'B'}<br />
                      <br />
                      <span className="trans-date">
                        {showDateFormate(transactionRow.createdAt)}{" "}
                        {showTimeFormate(transactionRow.createdAt)}
                      </span>
                    </li>
                    <li>Free Gift</li>
                    <li>{transactionRow.transIsFreeProductName}</li>
                    <li>
                      <span>{transactionRow.customer?.customerName || ""}</span>
                      <em>{transactionRow.customer?.customerPhone}</em>
                    </li>
                    <li className="textcenter">
                      <strong>Free</strong>
                      {transactionRow?.transCancel === "Yes" && (
                      <a href="#" className="canceled-lnk">
                        Cancelled
                      </a>
                    )}
                    </li>
                    <li>{thirdNote}</li>
                  </ul>
                  {transactionRow.transCancel == "Yes" &&
                    transactionRow.transactionParentID !== "" && (
                      <div className="canceled-trans">
                        Cancelled Transaction ID:{" "}
                        {transactionRow?.cancelTransID || ""}
                      </div>
                    )}
                </li>
              )}
            </React.Fragment>
          );
        }
      );

      return <ul>{transactionRowHtml}</ul>;
    } else {
      return (
        <ul>
          <li>
            <p> &nbsp;&nbsp;No Transactions</p>
          </li>
        </ul>
      );
    }
  }

  actCancelFunOld(selectedTrans, event) {
    event.preventDefault();
    if (Object.keys(selectedTrans).length > 0) {
      this.setState({ selected_trans: selectedTrans }, function () {
        $.magnificPopup.open({
          items: {
            src: ".canceltrans-popup",
          },
          type: "inline",
          closeOnBgClick: false,
        });
      });
    } else {
      let tempArr = Array();
      this.setState({ selected_trans: tempArr });
    }
  }

  actCancelFun(selectedTrans, event) {
    event.preventDefault();
    if (Object.keys(selectedTrans).length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to cancel this translation",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel it!",
        customClass: {
          confirmButton: "btn btn-primary me-3 waves-effect waves-light",
          cancelButton: "btn btn-label-secondary waves-effect",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          var postObject = {
            transactionID: selectedTrans._id,
          };

          return await axios
            .post(apiUrl + "merchant/cancelTransaction", postObject, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              return res.data;
            }).catch((e) => {
              console.log(e, 'eeeeee')
              return {messag:e?.message || 'Faild to cancel'};
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        console.log(result, "resultresult");
        if (result.value !== "" && result.value !== undefined) {
          if (result.value.status == "ok") {
            Swal.fire({
              icon: "success",
              title: "Cancel Transaction!",
              text: result.value.message,
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            }).then((dataarr) => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "Alert",
              text: result.value.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger waves-effect",
              },
            });
          }
        }
      });
    }
  }

  comfirmCancelFun(event) {
    event.preventDefault();
    /*let selectedTrans = this.state.selected_trans;
    if(Object.keys(selectedTrans).length > 0) {
      var postObject = {
        app_id: unquieID,
        user_id: cookie.load("LoginUserId"),
        outlet_id: cookie.load("LoginUserOutlet"),
        customer_id: selectedTrans.transaction_customer_id,
        transaction_id: selectedTrans.transaction_id
      };
      showLoaderLst('cancel-btn-cls','class');
      axios.post(apiUrl + "customer/canceltransaction", qs.stringify(postObject))
      .then((res) => {
        hideLoaderLst('cancel-btn-cls','class');
        if(res.data.status === "ok") {
          this.getTransData();
          $.magnificPopup.close();
        }
       });
    }*/
  }

  searchandFilter(event) {
    let trans_type = this.state.trans_type;
    let trans_orderby = this.state.trans_orderby;
    let search_text = this.state.search_text;
    if (event.target.name === "trnstype") {
      trans_type = event.target.value;
    } else if (event.target.name === "filter") {
      trans_orderby = event.target.value;
    } else if (event.target.name === "search_text") {
      search_text = event.target.value;
    }
    this.setState(
      {
        trans_type: trans_type,
        trans_orderby: trans_orderby,
        search_text: search_text,
      },
      function () {
        this.getTransData();
      }
    );
  }
  handleChangeDate(name, value) {
    this.setState({ [name]: value }, () => {
      this.getTransData();
    });
  }

  render() {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel trans-page-inner">
          <div className="container">
            <div className="trans-summary">
              <ul>
                <li>
                  Total Transaction : <b>{this.state.totalTransaction}</b>
                </li>
                <li>
                  Total Volume :{" "}
                  <b>{showPriceValue(this.state.totalTransactionValue)}</b>
                </li>
              </ul>
              <ul>
                <li>
                  Cash Voucher Sold :{" "}
                  <b>{showPriceValue(this.state.totalTransactionCashValue)}</b>
                </li>
                <li>
                  Cash Voucher Redeemed :{" "}
                  <b>{showPriceValue(this.state.totalSpendValue)}</b>
                </li>
              </ul>
            </div>
            <div className="history-search">
              <div className="history-search-form">
                <input
                  type="text"
                  name="search_text"
                  placeholder="Search Customer"
                  value={this.state.search_text}
                  onChange={this.searchandFilter.bind(this)}
                />
              </div>
            </div>
            <div className="history-filter">
              <img src={filteri} className="hf-icon" />
              <div className="hf-lhs">
                <label>Filter by :</label>
                <select
                  name="filter"
                  value={this.state.trans_orderby}
                  onChange={this.searchandFilter.bind(this)}
                >
                  <option value="">select</option>
                  <option value="low">Low to High</option>
                  <option value="high">High to Low</option>
                </select>
              </div>
              <div className="hf-rhs">
                <select
                  name="trnstype"
                  value={this.state.trans_type}
                  onChange={this.searchandFilter.bind(this)}
                >
                  <option value="">Type</option>
                  <option value="points">Credits</option>
                  <option value="products">Products</option>
                </select>
              </div>
            </div>
            <div className="filte-date">
              <div className="start_date">
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  dropdownMode="select"
                  className="form-control"
                  selected={this.state.start_date}
                  dateFormat="d-MM-yyyy h:mm aa"
                  placeholderText="Start Date"
                  onChange={this.handleChangeDate.bind(this, "start_date")}
                />
              </div>
              <div>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  showTimeSelect
                  dropdownMode="select"
                  className="form-control"
                  selected={this.state.end_date}
                  minDate={
                    this.state.start_date !== "" ? this.state.start_date : ""
                  }
                  dateFormat="d-MM-yyyy h:mm aa"
                  placeholderText="End Date"
                  onChange={this.handleChangeDate.bind(this, "end_date")}
                />
              </div>
            </div>
            <div className="history-table">
              <div className="history-table-header">
                <ul>
                  <li className="textcenter">Transaction ID</li>
                  <li className="textcenter">Type</li>
                  <li className="textcenter">Description</li>
                  <li className="textcenter">Customer</li>
                  <li className="textcenter">Value</li>
                  <li className="textcenter">Notes</li>
                </ul>
              </div>
              <div className="history-table-body">
                {this.transactionListFun()}
              </div>
            </div>
          </div>
        </div>

        <div
          id="canceltrans-popup"
          className="white-popup mfp-hide popup_sec canceltrans-popup"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">Transactions</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_right">
                  <h5>Are you sure you want to cancel this transaction?...</h5>
                  <div
                    className="button cancel-btn-cls"
                    onClick={this.comfirmCancelFun.bind(this)}
                  >
                    Yes Confirm
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Transactions;
