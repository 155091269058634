/* Live */
export const unquieID =
  localStorage.getItem("company_app_id") === null ||
  localStorage.getItem("company_app_id") === undefined
    ? ""
    : localStorage.getItem("company_app_id");

export const apiUrl =  'https://walletapi.epicpay.ai/api/';
export const baseUrl = 'https://merchant.epicpay.ai/';


/* export const apiUrl =  'http://localhost:3005/api/';
export const baseUrl = "http://localhost:3002/"; */
