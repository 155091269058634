/* eslint-disable */
import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { format } from "date-fns";
import { apiUrl, unquieID } from "../Settings/Config";
import {
  showLoaderLst,
  hideLoaderLst,
  showPriceValue,
  stripslashes,
} from "../Helpers/SettingHelper";

var Parser = require("html-react-parser");
var qs = require("qs");

class RedeemQRCode extends Component {
  constructor(props) {
    super(props);
    var usercredits =
      localStorage.getItem("usercredits") === null
        ? 0
        : localStorage.getItem("usercredits");
    this.state = {
      current_page: "Redeem Voucher",
      qr_details: [],
      qr_detail_error: "",
      user_credits: usercredits,
      redeemqr_error: "",
    };

    if (parseFloat(usercredits) > 0) {
      localStorage.removeItem("usercredits");
    }

    /*var qrcodetxt = (localStorage.getItem('qrcodetxt') === null) ? '' : localStorage.getItem('qrcodetxt');
    if(qrcodetxt !== '') {
      localStorage.removeItem("qrcodetxt");
      this.getVoucherData(qrcodetxt);
    } else {
      props.history.push("/");
    }*/
  }
  componentDidMount() {
    //$("body").addClass("hide-overlay");

    var qrcodetxt =
      localStorage.getItem("qrcodetxt") === null
        ? ""
        : localStorage.getItem("qrcodetxt");
    if (qrcodetxt !== "") {
      //  localStorage.removeItem("qrcodetxt");
      this.getVoucherData(qrcodetxt);
    } else {
      this.props.history.push("/");
    }
  }

  getVoucherData(qrCodeVal) {
    var postObject = {
      QRCode: qrCodeVal
    };
    showLoaderLst("redeem-page-main", "class");
    axios
      .post(apiUrl + "merchant/readQrcode", postObject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        hideLoaderLst("redeem-page-main", "class");
        if (res.data.status === "ok") {
          this.setState({ qr_details: res.data.result }, ()=> {
            var current_page = "";
            if(res.data.result[0].QRType==="BillPay") {
              current_page = "Bill Pay";
            }else if(res.data.result[0].QRType==="buyvoucher") {
              current_page = "Buy Voucher";
            }
            this.setState({current_page:current_page})
          });
        } else {
          this.setState({ qr_details: [], qr_detail_error: "yes" });
        }
      });
  }

  goBackTo(event) {
    event.preventDefault();
    this.props.history.push("/menu");
  }

  comfirmRedeemFun(event) {
    event.preventDefault();
    let qrDetails = this.state.qr_details;
    let userCredits = 0;
    if (qrDetails.length > 0) {
      var postObject = {
        QRCode: qrDetails[0].QRCode,
        QRID: qrDetails[0]._id,
        userCredits: userCredits,
      };

      showLoaderLst("redeem-btn-cls", "class");
      axios
        .post(apiUrl +  "merchant/redeemQrcode", postObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          hideLoaderLst("redeem-btn-cls", "class");
          if (res.data.status === "ok") {
            Swal.fire({
              icon: "success",
              title: "Redeemed!",
              text: "Bill payment approved successfully.",
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            }).then((dataarr) => {
              this.props.history.push("/transactions");
            });
            //this.setState({ qr_details: res.data.result_set });
          } else {
            this.setState({ redeemqr_error: res.data.message });
          }
        }).catch((e) => {
          hideLoaderLst("redeem-btn-cls", "class");
          var errorMsg = e?.response?.data?.massage || e.message;
          this.setState({ redeemqr_error: errorMsg });
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ redeemqr_error: "Invalide QR" });
    }
  }

  render() {
    let qrDetails = this.state.qr_details;
    var productVoucherBonusAmount = qrDetails[0]?.productVoucherBonusAmount || 0
    return (
      <div className="main-div redeem-page-main">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel redeem-page-inner">
          <div className="container">
            {qrDetails.length > 0 ? (
              <div className="redeem-profie">
                <div className="redeem-profie-box">
                  <figure></figure>
                  <figcaption>
                    <h2>{qrDetails[0].customerName}</h2>
                    <span>{qrDetails[0].customerPhone}</span>
                    <br/>
                    <label><b>Transaction ID:</b> {qrDetails[0]?.QRtransactionID||''}</label><br/>
                    <label><b>Transaction Time:</b> {(qrDetails.length>0)?format(new Date(qrDetails[0].createdAt), "yyyy-MM-dd hh:mm b"):''}</label>
                    
                  </figcaption>
                </div>

                {(qrDetails[0].QRType === "BillPay" || qrDetails[0].QRType === "products") && (
                  <div className="details payment-details">
                    {qrDetails[0].productName !== "" && qrDetails[0].productName !==null && (
                      <>
                        <div className="payment-info">
                          <div className="paid-info">
                            <div>
                              <strong>Customer Pays</strong>
                              <br />
                              <div>
                                {stripslashes(qrDetails[0].productName)}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <span>
                                {showPriceValue(qrDetails[0].productPrice)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="payment-info">
                          <div className="paid-info">
                            <div>
                              <strong>Customer Receives</strong>
                            </div>
                            <div>
                              {" "}
                              <span>
                                {showPriceValue(parseFloat(productVoucherBonusAmount) + parseFloat(qrDetails[0].productPrice))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {qrDetails[0].QRType === "BillPay" && (
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Bill for today</strong>
                          </div>
                          <div>
                            {" "}
                            <span>
                              {showPriceValue(qrDetails[0].billAmount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {qrDetails[0].QRType === "BillPay" && parseFloat(qrDetails[0].voucherBillAmount)>0 && (
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Redeem Voucher Amount</strong>
                          </div>
                          <div>
                            {" "}
                            <span>
                              -{showPriceValue(qrDetails[0].voucherBillAmount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}                   
                    {qrDetails[0].productName !== "" && qrDetails[0].productName !==null ? (
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Balance</strong>
                          </div>
                          <div>
                            <span>
                              {showPriceValue(parseFloat(productVoucherBonusAmount) + parseFloat(qrDetails[0].productPrice) - parseFloat(qrDetails[0].billAmount))}
                            </span>
                          </div>
                        </div>
                      </div>
                    ):(
                      parseFloat(qrDetails[0].balanceAmount)>0 &&(
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Balance to pay</strong>
                          </div>
                          <div>
                            <span>
                              {showPriceValue(qrDetails[0].balanceAmount)}
                            </span>
                          </div>
                        </div>
                      </div>
                      )
                    )}
                  </div>
                )}



                {/* (qrDetails[0].QRType === "BillPay") && (
                  <>
                    <div className="redeem-profie-points textcenter">
                      {qrDetails[0].QRType === "BillPay" && (
                        <>
                          <p>Bill Pay Amount</p>
                          <strong className="textcls">
                            {showPriceValue(qrDetails[0].billAmount)}
                          </strong>
                        </>
                      )}

                      {qrDetails[0].productName !== "" && (
                        <>
                          {qrDetails[0].QRType === "BillPay" ? (
                            <>
                              <p>{stripslashes(qrDetails[0].productName)}</p>
                              <strong className="textcls">
                                {showPriceValue(qrDetails[0].productPrice)}
                              </strong>
                            </>
                          ) : (
                            <>
                              <p>
                              {stripslashes(qrDetails[0].productName)}
                              </p>
                              <strong className="textcls">
                                {showPriceValue(qrDetails[0].productPrice)}
                              </strong>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {qrDetails[0].productLongDescription !== "" && qrDetails[0].productLongDescription !==null && (
                      <div className="voucher-detail-body">
                        {Parser(qrDetails[0].productLongDescription)}
                      </div>
                    )}
                  </>
                ) */}
                {this.state.redeemqr_error != "" && (
                  <p className="error_info">{this.state.redeemqr_error}</p>
                )}
                <div className="redeem-profie-btn textcenter">
                  <a
                    href={void 0}
                    className="button ghost-btn"
                    onClick={this.goBackTo.bind(this)}
                  >
                    Cancel
                  </a>
                  <a
                    href={void 0}
                    className="button redeem-btn-cls"
                    onClick={this.comfirmRedeemFun.bind(this)}
                  >
                    Confirm
                  </a>
                </div>
              </div>
            ) : (
              <div className="redeem-profie invalide-qr-div">
                {this.state.qr_detail_error == "yes" && (
                  <>
                    <div className="redeem-profie-box">
                      <div className="invalide-qr-cls">
                        <h2>Invalide QR Code</h2>
                        <span>
                          Sorry This voucher can not be reddemm at this outlet
                        </span>
                      </div>
                    </div>
                    <div className="redeem-profie-btn textcenter">
                      <a
                        href={void 0}
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this)}
                      >
                        Try Agin
                      </a>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RedeemQRCode;
