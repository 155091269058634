/* eslint-disable */
import cookie from "react-cookies";
import $ from "jquery";
import Swal from "sweetalert2";
import { format, subHours } from "date-fns";

/* stripslashes  */
export const stripslashes = function (str) {
  if (str !== "" && typeof str !== undefined && typeof str !== "undefined") {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
    return str;
  }
};

/* Random ID  */
export const randomId = function () {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

/*Reference ID Generate*/
export const getReferenceID = function () {
  if (typeof cookie.load("referenceId") === "undefined") {
    var randomKey = randomId();
    cookie.save("referenceId", randomKey, { path: "/" });
    return randomKey;
  } else {
    return cookie.load("referenceId");
  }
};

/* show Alert */
export const showAlert = function (header, message, type, autoClose = "No") {
  if (autoClose === "No") {
    var icon = "";
    if (type === "success") {
      icon = "success";
    } else if (type === "success") {
      icon = "warning";
    } else {
      icon = "error";
    }
    Swal.fire({
      title: header,
      html: message,
      icon: icon,
      customClass: {
        confirmButton: "btn btn-primary waves-effect waves-light",
      },
      buttonsStyling: false,
    });
  } else {
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"></div></div></div></div></div></div>'
    );
    setTimeout(function () {
      autoClose.close();
    }, 1800);
  }
};

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append(
        '<div class="spinner-border loader-sub-div" role="status"><span class="visually-hidden">Loading...</span></div>'
      );
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append(
        '<div class="spinner-border loader-sub-div" role="status"><span class="visually-hidden">Loading...</span></div>'
      );
  }
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

/* sample funtion */
export const showPriceValue = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = "$" + price.toFixed(2);
  return priceTxt;
};


export const showPriceSeparate = function (price, digit=2, type='sub') {
  if(price!=="") {
    var newPrice = String(price);
    if(newPrice.indexOf('.')>=0) {
      var splitprice = newPrice.split('.');
      if(splitprice.length>=2) {
        let firstDigit = splitprice[1].charAt(0);
        if(parseInt(firstDigit)>0) {
          digit = 1;
        }
        let secondDigit = splitprice[1].charAt(1);
        if(parseInt(secondDigit)>0) {
          digit = 2
        }
      }
     
    }
  }
  
  price = price !== "" ? parseFloat(price) : 0.0;

  var priceTxt = (
    <>
    {type!=='no' &&
    (type==='sub' ? ( 
      <sup>$</sup>):(
        '$'
      ))}
      {numberWithCommas(price.toFixed(digit))}
    </>
  );
  return priceTxt;
};

export const numberWithCommas = function (x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

export const userID = function () {
  return cookie.load("loginID");
};
export const CompanyID = function () {
  return cookie.load("companyID");
};
export const clientID = function () {
  return cookie.load("clientID");
};

/* show Loader */
export const showLoaderLst = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  }
};

/* hide Loader */
export const hideLoaderLst = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

export const showDateTimeFormate = function (dateTime) {
  if(dateTime!=="" && dateTime!==null) {
     return format(new Date(dateTime),"dd/MM/yyyy h:m a");
  }
};

export const showDateFormate = function (dateTime) {
  if(dateTime!=="" && dateTime!==null) {
     return format(subHours(new Date(dateTime), 8),"dd/MM/yy");
  }
};

export const showTimeFormate = function (dateTime) {
  if(dateTime!=="" && dateTime!==null) {
     return format(subHours(new Date(dateTime), 8),"hh:mm a");
  }
};